import { getBalanceInEther } from 'utils/formatBalance';
import { getFarms, getMasterChefAbi, getMasterChefAddress, getRewardEmissionRateFunctionName } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import LpPairAbi from 'config/abi/LpPair.json';
import multicall from 'utils/multicall';
import { FarmCategory } from 'types/farm';
import { getEarnFarmsInfo } from 'utils/backend';

// fetch reward pool farms (smelt reward pools and dead pools)
export const fetchPoolFarms = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const farms = getFarms(selectedChainid);

    const farmsAddInfo = await getEarnFarmsInfo({})
    // get farm onchain info
    const farmsOnchainData = await Promise.all(
      farms.map(async (row: any) => {
        try{
          const masterChefAddress = getMasterChefAddress(selectedChainid, row.farmType, row.category, row.masterChefAddress);
          if (!masterChefAddress) return { ...row };
          const rewardEmissionRateFunctionName = getRewardEmissionRateFunctionName(row.farmType, row.category);
    
          if (row.category === FarmCategory.TEAM) {
            return {
              ...row,
              poolWeight: 0,
              tvl: 0,
              rewardPerSecond: 0,
            };
          }

          let token0Amount = 0
          let token1Amount = 0
          let poolWeight = 0
          let poolTvl = 0
          let rewardPerSecond = 0
          let tokenInLP = 0

          for( let i = 0; i < farmsAddInfo.length; i+=1 ){
            if( farmsAddInfo[i].master_pool === row.masterChefAddress.toLowerCase() && farmsAddInfo[i].staking_token === row.stakingToken.toLowerCase() ){

              const totalAllocPoint = getBalanceInEther(farmsAddInfo[i].total_alloc_point)
              
              if (rewardEmissionRateFunctionName) {
                const allocPoint = getBalanceInEther(farmsAddInfo[i].allocation_point);
                const totalAllocPointRaw = totalAllocPoint;
                poolWeight = allocPoint / totalAllocPointRaw;
                rewardPerSecond = Number(farmsAddInfo[i].reward_per_sec)
              }
              else {
                const allocPoint = getBalanceInEther(farmsAddInfo[i].allocation_point);
                const totalAllocPointRaw = totalAllocPoint;
                poolWeight = allocPoint / totalAllocPointRaw;
                rewardPerSecond = 0.00115
              }
              poolTvl = getBalanceInEther(farmsAddInfo[i].staking_token_balance);

              // BBOND pool (staking token is not LP)
              if (row.stakingToken0 === '0x0000000000000000000000000000000000000000') {
                // 

                return {
                  ...row,
                  poolWeight,
                  tvl: poolTvl,
                  rewardPerSecond,
                };
              }

              let isZeroToken = false

              const tokenSupply = getBalanceInEther(farmsAddInfo[i].total_supply0);
              const tokenSupply1 = getBalanceInEther(farmsAddInfo[i].total_supply1);

              if( tokenSupply1 > tokenSupply )
              {
                   isZeroToken = true
              } 
              let stakingTokenTotalSupply = 0;
              stakingTokenTotalSupply = getBalanceInEther(farmsAddInfo[i].staking_supply)

              const stakingLpTokenRatio = poolTvl / stakingTokenTotalSupply;

              if( isZeroToken ){
                // eslint-disable-next-line no-restricted-properties
                token0Amount = getBalanceInEther(farmsAddInfo[i].lp_token0_balance) * Math.pow(10, 18 - Number(farmsAddInfo[i].stakingToken0_decimals)) * stakingLpTokenRatio;
                // eslint-disable-next-line no-restricted-properties
                token1Amount = getBalanceInEther(farmsAddInfo[i].lp_token1_balance) * Math.pow(10, 18 - Number(farmsAddInfo[i].stakingToken1_decimals)) * stakingLpTokenRatio;
              }
              else{
                // eslint-disable-next-line no-restricted-properties
                token1Amount = getBalanceInEther(farmsAddInfo[i].lp_token0_balance) * Math.pow(10, 18 - Number(farmsAddInfo[i].stakingToken0_decimals)) * stakingLpTokenRatio;
                // eslint-disable-next-line no-restricted-properties
                token0Amount = getBalanceInEther(farmsAddInfo[i].lp_token1_balance) * Math.pow(10, 18 - Number(farmsAddInfo[i].stakingToken1_decimals)) * stakingLpTokenRatio;
              }

              if( isZeroToken )
                tokenInLP = tokenSupply/ Number(poolTvl);
              else
                tokenInLP = tokenSupply1/ Number(poolTvl);

            }
          }

          return {
            ...row,
            token0Amount,
            token1Amount,
            poolWeight,
            tvl: poolTvl,
            rewardPerSecond,
            tokenInLP,
            isLoaded: true
          };
        }
        catch(err){
          console.log(err)
 
          return{
            ...row
          }
        }
      })
    );

    return farmsOnchainData;
  } catch (error: any) {
    console.log(error)
    return [];
  }
};

export const fetchGlobalFarmData = async (chainId: string): Promise<any> => {
  const smeltRewardPoolFarmsData = await fetchPoolFarms(chainId);

  return {
    data: [...smeltRewardPoolFarmsData],
  };
};

import { getBalanceInEther } from 'utils/formatBalance';
import { getStakingPoolAbi, getStakingPools, getTreasuryAbi } from 'utils/stakings';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';

import multicall from 'utils/multicall';
import InfinitePrinterAbi from 'config/abi/InfinitePrinter.json';
import { getTreasuryAddress } from 'utils/addressHelpers';
import { getEarnStakesInfo } from 'utils/backend';

export const fetchStakingPools = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const pools = getStakingPools(selectedChainid);
    const treasuryAddr = getTreasuryAddress(selectedChainid);

    const stakeAddInfo = await getEarnStakesInfo({})
    // get pool onchain info
    const poolsOnchainData = await Promise.all(
      pools.map(async (row: any) => {
        const poolAddress = row.address;
        const poolType = row.type;

        if (poolType === 'Acropolis') {

          for( let i = 0; i < stakeAddInfo.length; i+=1 ){
            if( poolAddress.toLowerCase() === stakeAddInfo[i].address ){
              return {
                ...row,
                currentEpoch: stakeAddInfo[i].epoch,
                nextEpochStartTimestamp: stakeAddInfo[i].next_epoch_time,
                withdrawLockupEpochs: stakeAddInfo[i].withdraw_lockups,
                tvl: stakeAddInfo[i].tvl,
                rewardEmissionRate: stakeAddInfo[i].rewards_emission_rate,
                rewardPerShare: stakeAddInfo[i].rewards_per_share,
                period: stakeAddInfo[i].period,
                rewardLockupEpochs: stakeAddInfo[i].rewards_lockups
              };
            }
    
            } 
          }

          for( let i = 0; i < stakeAddInfo.length; i+=1 ){
            if( poolAddress.toLowerCase() === stakeAddInfo[i].address ){
              return {
                ...row,
                currentEpoch: stakeAddInfo[i].epoch,
                nextEpochStartTimestamp: stakeAddInfo[i].next_epoch_time,
                withdrawLockupEpochs: stakeAddInfo[i].withdraw_lockups,
                tvl: stakeAddInfo[i].tvl,
                rewardEmissionRate: stakeAddInfo[i].rewards_emission_rate,
                rewardPerShare: stakeAddInfo[i].rewards_per_share,
                infinitePrinterApr: stakeAddInfo[i].apr / 10,
                rewardTokenCirculatingSupply: stakeAddInfo[i].reward_token_circulating,
                period: stakeAddInfo[i].period,
                rewardLockupEpochs: stakeAddInfo[i].rewards_lockups
              };
            }

            }
            return{
              ...row
            } 
      })
    );

    return poolsOnchainData;
  } catch (error: any) {
    return [];
  }
};

export const fetchGlobalStakingData = async (chainId: string): Promise<any> => {
  const stakingPools = await fetchStakingPools(chainId);
  return {
    data: [...stakingPools],
  };
};

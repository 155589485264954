import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { BigNumber, ethers } from 'ethers';

import { useLpContract, useMasterChefContract } from 'hooks/useContract';
import { fetchFarmGlobalDataAsync, fetchFarmUserDepositDataAsync, fetchFarmUserTokenDataAsync } from 'state/actions';
import { useAppSelector } from 'state/hooks';
import { FarmInfo } from 'types/farm';
import { setPendingTxHash } from 'state/modal/modalSlice';
import { useNotification } from 'hooks/useNotification';

export const useFarmMasterChef = (farmInfo: FarmInfo) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const masterChefContract = useMasterChefContract(farmInfo.masterChefAddress, farmInfo.farmType);
  const stakingTokenContract = useLpContract(farmInfo.stakingToken);
  const receiptTokenContract = useLpContract(farmInfo.receiptToken);
  const { selectedChainId } = useAppSelector((state) => state.chain);

  const { onShowNotification } = useNotification();

  const handleApproveStakingToken = useCallback(async (): Promise<string | undefined> => {
    if (!account || !masterChefContract || !stakingTokenContract) return '';

    const tx = await stakingTokenContract.approve(masterChefContract.address, ethers.constants.MaxUint256);
    onShowNotification({
      title: 'Transaction pending',
      description: 'Approve Staking Token',
      hasView: true,
      txHash: tx.hash,
    });

    dispatch(setPendingTxHash(tx.hash));

    const receipt = await tx.wait();

    if (receipt.status !== 1) {
      throw new Error();
    }

    dispatch(fetchFarmUserTokenDataAsync(account, selectedChainId, farmInfo, 0));

    return tx.txHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, stakingTokenContract, masterChefContract]);

  const handleApproveReceiptToken = useCallback(async (): Promise<string | undefined> => {
    if (farmInfo.farmType === 'dealPool' || farmInfo.farmType === 'deadPool2' || farmInfo.farmType === 'deadPool3') return '';
    if (!account || !masterChefContract || !receiptTokenContract) return '';

    const tx = await receiptTokenContract.approve(masterChefContract.address, ethers.constants.MaxUint256);
    onShowNotification({
      title: 'Transaction Pending',
      description: 'Approve Receipt Token',
      hasView: true,
      txHash: tx.hash,
    });
    dispatch(setPendingTxHash(tx.hash));

    const receipt = await tx.wait();

    if (receipt.status !== 1) {
      throw new Error();
    }

    dispatch(fetchFarmUserTokenDataAsync(account, selectedChainId, farmInfo, 0));

    return tx.txHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, stakingTokenContract, masterChefContract]);

  const handleDeposit = useCallback(
    async (amount: BigNumber): Promise<string | undefined> => {
      if (!account || !masterChefContract) return '';

      const tx = await masterChefContract.deposit(farmInfo.poolId, amount);

      onShowNotification({
        title: 'Transaction Pending',
        description: 'Deposit',
        hasView: true,
        txHash: tx.hash,
      });
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchFarmUserTokenDataAsync(account, selectedChainId, farmInfo, 0));
      dispatch(fetchFarmGlobalDataAsync(selectedChainId));
      dispatch(fetchFarmUserDepositDataAsync(account, selectedChainId, farmInfo, 0));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, masterChefContract]
  );

  const handleWithdraw = useCallback(
    async (amount: BigNumber): Promise<string | undefined> => {
      if (!account || !masterChefContract) return '';

      const tx = await masterChefContract.withdraw(farmInfo.poolId, amount);
      onShowNotification({
        title: 'Transaction Pending',
        description: amount.eq(BigNumber.from(0)) ? 'Claim Reward' : 'Withdraw',
        hasView: true,
        txHash: tx.hash,
      });
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchFarmUserTokenDataAsync(account, selectedChainId, farmInfo, 0));
      dispatch(fetchFarmGlobalDataAsync(selectedChainId));
      dispatch(fetchFarmUserDepositDataAsync(account, selectedChainId, farmInfo, 0));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, masterChefContract]
  );

  const handleClaim = useCallback(
    async (): Promise<string | undefined> => handleWithdraw(BigNumber.from(0)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, masterChefContract]
  );

  return {
    onApproveStakingToken: handleApproveStakingToken,
    onApproveReceiptToken: handleApproveReceiptToken,
    onDeposit: handleDeposit,
    onWithdraw: handleWithdraw,
    onClaim: handleClaim,
  };
};

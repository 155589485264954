import { BigNumber } from '@ethersproject/bignumber';
import { hexStripZeros } from '@ethersproject/bytes';
import { Web3Provider } from '@ethersproject/providers';
import { providers } from 'ethers';

import { CHAIN_INFO } from 'config/constants/chains';
import { NETWORK_RPC_URLS } from 'config/constants/rpc';
import { getItem } from 'utils/localstorage';
import { getTokenInfo } from 'utils/tokens';
import getNodeUrl from './getRpcUrl';

export interface SwitchNetworkArguments {
  library: Web3Provider;
  chainId: number;
}

function getRpcUrls(chainId: number): [string] {
  const rpcUrl = getNodeUrl(chainId);

  if (rpcUrl) {
    return [rpcUrl];
  }
  // Our API-keyed URLs will fail security checks when used with external wallets.
  throw new Error('RPC URLs must use public endpoints');
}

// provider.request returns Promise<any>, but wallet_switchEthereumChain must return null or throw
// see https://github.com/rekmarks/EIPs/blob/3326-create/EIPS/eip-3326.md for more info on wallet_switchEthereumChain
// eslint-disable-next-line import/prefer-default-export
export async function switchToNetwork({ library, chainId }: any): Promise<null | void> {
  let provider = library?.provider;

  if (!provider?.request) {
    const { provider: connectedProvider } = new providers.Web3Provider(library);
    provider = connectedProvider;
    if (!provider?.request) {
      return;
    }
  }
  const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString());

  window.localStorage.setItem("selectedChainId", String(chainId));

  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: formattedChainId }],
    });
  } catch (error: any) {
    // 4902 is the error code for attempting to switch to an unrecognized chainId
    if (error.code === 4902) {
      const info = CHAIN_INFO[chainId];

      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: formattedChainId,
            chainName: info.label,
            rpcUrls: getRpcUrls(chainId),
            nativeCurrency: info.nativeCurrency,
            blockExplorerUrls: [info.explorer],
          },
        ],
      });
      // metamask (only known implementer) automatically switches after a network is added
      // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
      // metamask's behavior when switching to the current network is just to return null (a no-op)
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: formattedChainId }],
        });
        // eslint-disable-next-line @typescript-eslint/no-shadow, no-empty
      } catch (error) {}
    } else {
      throw error;
    }
  }
}

/* eslint-disable no-console */
/**
 * Prompt the user to add Ethereum as a network on Metamask, or switch to Ethereum if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (selectedChainId?: string): Promise<boolean> => {
  const provider = (window as any).ethereum;

  if (provider) {
    const chainId = Number(selectedChainId).toString(16);

    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${chainId}`,
          },
        ],
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined");
    return false;
  }
  return false;
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 */
export const addTokenToMetamask = async (tokenAddress: string) => {
  const tokenInfo = getTokenInfo(tokenAddress);
  if (!tokenInfo) return;

  try{
    await (window as any).ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenInfo.address,
          symbol: tokenInfo.symbol,
          decimals: 18,
          image: window.origin + tokenInfo.tokenImage,
        },
      },
    });
  }
  catch(except){
    console.log(except)
  }
};

/**
 * Prompt the user to add a custom lp token to metamask
 * @param tokenAddress
 */
export const addLpTokenToMetamask = async (tokenAddress: string, tokenImage: string, tokenSymbol: string) => {
  try{
    await (window as any).ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: 18,
          image: window.origin + tokenImage,
        },
      },
    });
  }
  catch(except){
    console.log(except)
  }
};

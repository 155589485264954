/* eslint-disable no-underscore-dangle */
import multicall, { multicallNative} from 'utils/multicall';
import OBOLTokenAbi from 'config/abi/OBOL.json';
import { getBalanceInEther } from 'utils/formatBalance';
import { getTokens } from 'utils/tokens';
import { getOverviewTokensInfo, getTokensInfo } from 'utils/backend';
import { getBoilerRoomAddress, getTeamTreasuryAddress } from 'utils/addressHelpers';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import { getTokenPriceFromCGC } from 'utils/coingecko';
import { getTokenPriceFromDexTools } from 'utils/dextools';

const TAX_RATIO_DENOMINATOR = 10000;

export const fetchGlobalTokenData = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const tokens = getTokens(selectedChainid);
    const treasuryAddr = getTeamTreasuryAddress(selectedChainid);
    const boilerRoomAddr = getBoilerRoomAddress(selectedChainid);
    let circulatingSupply = 0;
    circulatingSupply = 0;
    let successCount = 0;
    const additionalTokenData = await getOverviewTokensInfo({})
    const offchainData = await getTokensInfo({});
    const onchainData = await Promise.all(
      tokens.map(async (token: any) => {
        const tokenAddr = token.address;
        let buyTax = 0;
        buyTax = 0;
        let sellTax = 0;
        sellTax = 0;
        let burntAmnt = 0;
        burntAmnt = 0;
        let totalSupply
        let tokenPriceUsd = -1;
        tokenPriceUsd = -1;

        const tokenOffChainInfo = offchainData.find((row: any) =>
          token.symbol === 'BBOND'
            ? row?.symbol === 'BASED'
            : row?.tokenAddress.toLowerCase() === token?.address.toLowerCase()
        );

        try 
        {
          
          for( let i = 0; i < additionalTokenData.length; i+=1 ){
            if( additionalTokenData[i].address === token.address.toLowerCase() ){
              totalSupply = additionalTokenData[i].total_supply
              burntAmnt = additionalTokenData[i].burn_amount
              buyTax = additionalTokenData[i].buy_fee / TAX_RATIO_DENOMINATOR;
              sellTax = additionalTokenData[i].sell_fee / TAX_RATIO_DENOMINATOR;
              circulatingSupply = additionalTokenData[i].circulating_supply
            }
          }
        }
        catch(err){
// 
        }

        const priceUSD = tokenOffChainInfo ? tokenOffChainInfo.priceUSD || tokenPriceUsd : tokenPriceUsd
        const priceFTM = tokenOffChainInfo ? tokenOffChainInfo?.priceInWFTM || -1 : -1

        successCount += 1
        return {
          ...token,
          buyTax,
          sellTax,
          priceInUsd: priceUSD,
          priceInFtm: priceFTM,
          circulationSupply: circulatingSupply,
          totalSupply,
          burntAmnt,
        };
      })
    );

    return {
      data: onchainData,
    };
  } catch (error: any) {
    console.log(error)
    return {
      data: [],
    };
  }
};

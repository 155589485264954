export enum FarmCategory {
  ACTIVE,
  RETIRED,
  ACTIVE_RETIRED,
  TEAM,
  TEST,
  INVISIBLE
}

export interface FarmInfo {
  name: string;
  poolId: number;
  stakingToken: string;
  rewardToken: string;
  receiptToken: string;
  rewardTokenName: string;
  rewardTokenDecimal: number;
  tokenImage: string;
  rewardTokenImage: string;
  masterChefAddress: string;
  farmType: string;
  chainId: number;
  apr?: number;
  dailyApr?: number;
  tvlInUsd?: number;
  userInfo: any;
  category: FarmCategory;
  location?: string;
  isTest?: boolean;
  autocompounder: string;
  place: FarmCategory;
  isFake?: boolean;
  isAprZero?: boolean;
  isRecieptDisabled?: boolean;
  isReciept?: boolean;
  updateTime: number;
  isLoaded: boolean;
}

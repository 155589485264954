import { getBalanceInEther } from 'utils/formatBalance';
import { getMasterChefAbi, getNftFarms, getRewardEmissionRateFunctionName } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import GodNftAbi from 'config/abi/GodNft.json';
import multicall from 'utils/multicall';
import { FarmCategory } from 'types/farm';
import { getEarnFarmsInfo } from 'utils/backend';

// fetch nft farms
export const fetchNftFarms = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const farms = getNftFarms(selectedChainid);
    const farmsAddInfo = await getEarnFarmsInfo({})

    // get farm onchain info
    const farmsOnchainData = await Promise.all(
      farms.map(async (row: any) => {
        const { masterChefAddress } = row;
        if (!masterChefAddress) return { ...row };
        if( row.isFake || row.category === FarmCategory.INVISIBLE ) {
          return {
            ...row,
            poolWeight: 0,
            tvl: -1,
            rewardPerSecond: 0,
          }
        }
        const rewardEmissionRateFunctionName = getRewardEmissionRateFunctionName(row.farmType, row.category);

        let poolWeight = 0;
        let rewardPerSecond = 0;
        let tokenBalance = 0;

        for( let i = 0; i < farmsAddInfo.length; i+=1 ){

            if( farmsAddInfo[i].master_pool === row.masterChefAddress.toLowerCase() && farmsAddInfo[i].staking_token === row.stakingToken.toLowerCase() ){
              const totalAllocPoint = getBalanceInEther(farmsAddInfo[i].total_alloc_point)
              if (rewardEmissionRateFunctionName) {
                const allocPoint = getBalanceInEther(farmsAddInfo[i].allocation_point);
                const totalAllocPointRaw = totalAllocPoint;
                poolWeight = allocPoint / totalAllocPointRaw;
                rewardPerSecond = Number(farmsAddInfo[i].reward_per_sec)
              } else {
                const allocPoint = getBalanceInEther(farmsAddInfo[i].allocation_point);
                const totalAllocPointRaw = totalAllocPoint;
                poolWeight = allocPoint / totalAllocPointRaw;
                rewardPerSecond = 0.00115
              }
              tokenBalance = Number(farmsAddInfo[i].staking_token_balance)
            }
          }

        return {
          ...row,
          poolWeight,
          tvl: tokenBalance,
          rewardPerSecond,
        };
      })
    );

    return farmsOnchainData;
  } catch (error: any) {
    console.log(error)
    return [];
  }
};

export const fetchGlobalFarmData = async (chainId: string): Promise<any> => {
  const smeltRewardPoolFarmsData = await fetchNftFarms(chainId);

  return {
    data: [...smeltRewardPoolFarmsData],
  };
};

import { createSlice } from '@reduxjs/toolkit';

import { TokenInfo } from 'types/token';

import { fetchGlobalTokenData } from './fetchGlobalTokens';

interface TokensState {
  data: TokenInfo[];
}

const initialState: TokensState = {
  data: [],
};

let updateTime = 0
let lock = false

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
    },

    setTokenGlobalData: (state, action) => {
      state.data = [...action.payload.data];
    },
  },
});

export const { reset, setTokenGlobalData } = tokenSlice.actions;

// fetch global token info
export const fetchTokenGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    
    const currentTime = new Date().getTime() / 1000
    const diff = currentTime - updateTime
    console.log(`Diff ${diff}`)

    if( diff < 30 )
      return

    if( lock ){
      console.log("Busy")
      return
    }
    lock = true;
    console.log("Start Fetching data ", updateTime);
    const { data } = await fetchGlobalTokenData(chainId);

    dispatch(
      setTokenGlobalData({
        data,
      })
    );
    lock = false
    updateTime = new Date().getTime() / 1000
    console.log(updateTime)
  };

export default tokenSlice.reducer;

import React, { FC, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import {
  fetchBridgeInfoAsync,
  fetchBridgeNftInfoAsync,
  // ft farms
  fetchFarmGlobalDataAsync,
  fetchFarmGlobalUserDataAsync,
  fetchLiquidityGlobalDataAsync,
  fetchMarketInfoAsync,
  // nft farms
  fetchNftFarmGlobalDataAsync,
  fetchNftFarmGlobalUserDataAsync,
  // twisted nodes
  fetchNodeGlobalDataAsync,
  // staking pools
  fetchStakingGlobalDataAsync,
  fetchStakingGlobalUserDataAsync,
  // theatre
  fetchTheatreGlobalDataAsync,
  fetchTheatreUserDataAsync,
  // tokens
  fetchTokenGlobalDataAsync,
} from 'state/actions';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import MainLayout from 'layouts/MainLayout';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import { setSelectedChainId } from 'state/chain/chainSlice';
import { setTokenGlobalData } from 'state/tokens/tokensSlice';
import NftTokenPage from 'components/Nft/[contractAddress]/[nftToken]';

const OverviewPage = React.lazy(() => import('./pages/Overview'));
const DexPage = React.lazy(() => import('./pages/Dex'));
const EarnPage = React.lazy(() => import('./pages/Earn'));
const BridgePage = React.lazy(() => import('./pages/Bridge'));
const NftPage = React.lazy(() => import('./pages/Nft'));
const TheatrePage = React.lazy(() => import('./pages/Theatre'));

const Router: FC = () => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);
  const { loaded : StakingLoaded } = useAppSelector((state) => state.stakingPools);
  const { loaded : FarmsLoaded } = useAppSelector((state) => state.farms);
  const { loaded : NftLoaded } = useAppSelector((state) => state.nftFarms);

  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);

  const [mainLoaded, setMainLoaded] = useState(false);

  const fetchData = async () => {
    try{
      if( isWalletChecked ){
        // dispatch(fetchLiquidityGlobalDataAsync(selectedChainId));
        // dispatch(fetchFarmGlobalDataAsync(selectedChainId));
        dispatch(fetchTokenGlobalDataAsync(selectedChainId));
        // dispatch(fetchStakingGlobalDataAsync(selectedChainId));
        // dispatch(fetchNftFarmGlobalDataAsync(selectedChainId));
        // dispatch(fetchNodeGlobalDataAsync(selectedChainId));
        // dispatch(fetchTheatreGlobalDataAsync(selectedChainId));
        // dispatch(fetchBridgeInfoAsync(selectedChainId));
        // dispatch(fetchBridgeNftInfoAsync(selectedChainId));
        // dispatch(fetchMarketInfoAsync(selectedChainId));
        setMainLoaded(true);
      }
    }
    catch(err) {
      console.log(err)
    }
    if( Number(selectedChainId) === 137 ) {
      setMainLoaded(true);
    }
  };

  const fetchUserData = async () => {
    if (!account ) return;
    try{
      // dispatch(fetchFarmGlobalUserDataAsync(account, selectedChainId));

      // fetch staking data
      // dispatch(fetchStakingGlobalUserDataAsync(account, selectedChainId));

      // dispatch(fetchTheatreUserDataAsync(account, selectedChainId));
    }
    catch(err){
      console.log(err)
    }

    try{
      // if( Number(selectedChainId) === 250 )
      //   dispatch(fetchNftFarmGlobalUserDataAsync(account, selectedChainId));
    }
    catch(err){
      // 
    }
  };

  useEffect(() => {
    if(isWalletChecked){
      dispatch(setTokenGlobalData({ data: [] }));
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId, isWalletChecked]);

  useEffect(() => {
    // fetch farm data
    const interval = setInterval(() => {
      if( !StakingLoaded || StakingLoaded ){
        setCounter2(counter2 + 1);
      }else {
          fetchUserData();
          clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, selectedChainId, counter2, isWalletChecked]);

  useEffect(() => {
    if (!selectedChainId) {
          window.localStorage.setItem("selectedChainId", String(DEFAULT_ACTIVE_CHAIN_ID));
         dispatch(setSelectedChainId(String(DEFAULT_ACTIVE_CHAIN_ID)));
     }
    const interval = setInterval(() => {
      setCounter(counter + 1);
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, selectedChainId, isWalletChecked])

  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout isLodaded={mainLoaded} />,
      children: [
        { index: true, element: <Navigate replace to="/overview" /> },
        { path: '/overview', element: <OverviewPage /> },
        { path: '/earn', element: <EarnPage /> },
        { path: '/dex', element: <DexPage /> },
        { path: '/bridge', element: <BridgePage /> },
         { path: '/nft', element: <NftPage /> },
        { path: '/theatre', element: <TheatrePage />},
         { path: '/nft/:contractAddress/:nftToken', element: <NftTokenPage/>}
      ],
    },

    { path: '*', element: <Navigate replace to="/" /> },
  ]);

  return routes;
};

export default Router;

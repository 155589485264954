import { createSlice } from '@reduxjs/toolkit';

import { StakingInfo } from 'types/staking';
import { fetchGlobalStakingData } from './fetchGlobalStakings';
import { fetchStakingUserData, fetchUserStakingDataSingle, fetchUserTokenDataSingle } from './fetchUserStakings';

interface StakingState {
  data: StakingInfo[];
  loaded: boolean;
}

const initialState: StakingState = {
  data: [],
  loaded: false
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
      state.loaded = false;
    },

    setStakingGlobalData: (state, action) => {
      state.data = action.payload.data.map((row: any, i: number) => ({
        ...row,
        userInfo: state.data[i]?.userInfo || {},
      }));
      state.loaded = true;
    },

    setStakingUserData: (state, action) => {
      const { data } = action.payload;

      // update stakings
      state.data = state.data.map((row: StakingInfo, index: number) => {
        if (row?.name === data[index]?.name) {
          return {
            ...row,
            userInfo: {
              ...row.userInfo,
              ...data[index],
            },
          };
        }
        return row;
      });

    },

    setStakingUserTokenData: (state, action) => {
      const { data } = action.payload;

      state.data = state.data.map((row: StakingInfo) => {
        if (row.name === data.name) {
          return {
            ...row,
            userInfo: {
              ...row.userInfo,
              userStakingTokenAllowance: data.userStakingTokenAllowance,
              userStakingTokenBalance: data.userStakingTokenBalance,
            },
          };
        }
        return row;
      });
    },

    setStakingUserDepositData: (state, action) => {
      const { data } = action.payload;

      state.data = state.data.map((row: StakingInfo) => {
        if (row.name === data.name) {
          return {
            ...row,
            userInfo: {
              ...row.userInfo,
              stakedBalance: data.stakedBalance,
              rewardTokenBalance: data.rewardTokenBalance,
              updateIndex: data.updateIndex,
              canWithdraw: data.canWithdraw,
              canClaimReward: data.canClaimReward,
              epochTimerStart: data.epochTimerStart,
              isUpdated: data.isUpdated
            },
          };
        }
        return row;
      });
    },
  },
});

export const { reset, setStakingGlobalData, setStakingUserData, setStakingUserTokenData, setStakingUserDepositData } =
  stakingSlice.actions;

// fetch global staking info
export const fetchStakingGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalStakingData(chainId);

    dispatch(
      setStakingGlobalData({
        data,
      })
    );
  };

// fetch staking user token info
export const fetchStakingUserTokenDataAsync =
  (account: string, pool: any) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserTokenDataSingle(account, pool);

    dispatch(
      setStakingUserTokenData({
        data,
      })
    );
  };

// fetch staking user token info
export const fetchStakingUserDepositDataAsync =
  (account: string, pool: any, updateIndex: number) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserStakingDataSingle(account, pool, updateIndex);
    
    dispatch(
      setStakingUserDepositData({
        data,
      })
    );
  };

// fetch global staking user info
export const fetchStakingGlobalUserDataAsync =
  (account: string, chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchStakingUserData(account, chainId);

    dispatch(
      setStakingUserData({
        data,
      })
    );
  };

export default stakingSlice.reducer;

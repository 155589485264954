import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

import { GRAPHQL_URL } from 'config/constants/backend';

export const graphqlClient = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache(),
});

const tokensQuery = `
  query tokensQuery($limit: Int!, $skip: Int!) {
    tokensMany(limit: $limit, skip: $skip)
      {
        tokenAddress
        symbol
        decimals
        priceInUSD
        priceUSD
        priceInWFTM
        tradeVolume
      }
  }
`;

export const getTokensInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(tokensQuery),
      variables: {
        limit: limit || 50,
        skip: skip || 0,
      },
      fetchPolicy: 'network-only',
    });

    return result.data.tokensMany;
  } catch (err) {
    return [];
  }
};

const tokensQueryMarketVolumes = `
  query tokensQueryMarketVolumes($collectionNFT: String!, $startTime: Int!, $endTime: Int!) {
    getMarketVolumesByDate(collectionNFT: $collectionNFT, startTime: $startTime, endTime: $endTime)
    {
      collectionNFT,
      counterBuy,
      counterSell,
      maxPriceBuy,
      maxPriceSell,
      midPriceBuy,
      midPriceSell,
      minPriceSell,
      volumeBuy,
      volumeSell,
      timeStamp
    }
  }
`;

export const getMarketVolume = async ({ collectionNFT, startTime, endTime }: { collectionNFT: string; startTime: number; endTime: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(tokensQueryMarketVolumes),
      variables: {
        collectionNFT: collectionNFT.toLocaleLowerCase(),
        startTime,
        endTime,
      },
      fetchPolicy: 'network-only',
    });
    return result.data.getMarketVolumesByDate;
  } catch (err) {
    return [];
  }
};

const queryMarketSells = `
  query getTransSellsByDate($collectionNFT: String!, $startTime: Int!, $endTime:Int!){
    getTransSellsByDate(collectionNFT: $collectionNFT, startTime: $startTime, endTime: $endTime){
      amount,
      collectionNFT,
      blockNumber,
      expireTime,
      nftId,
      paymentToken,
      seller,
      timeStamp
    }
  }
`;

export const getMarketSells = async ({ collectionNFT, startTime, endTime }: { collectionNFT: string; startTime: number; endTime: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(queryMarketSells),
      variables: {
        collectionNFT: collectionNFT.toLocaleLowerCase(),
        startTime,
        endTime,
      },
      fetchPolicy: 'network-only',
    });
    return result.data.getTransSellsByDate;
  } catch (err) {
    return [];
  }
};

const queryMarketBuys = `
query getTransBuysByDate($collectionNFT: String!, $startTime: Int!, $endTime:Int!){
  getTransBuysByDate(collectionNFT: $collectionNFT, startTime: $startTime, endTime: $endTime){
    amount,
    blockNumber,
    buyer,
    collectionNFT,
    nftId,
    paymentToken,
    serviceFee,
    timeStamp,
    transactionHash
  }
}
`;

export const getMarketBuys = async ({ collectionNFT, startTime, endTime }: { collectionNFT: string; startTime: number; endTime: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(queryMarketBuys),
      variables: {
        collectionNFT: collectionNFT.toLocaleLowerCase(),
        startTime,
        endTime,
      },
      fetchPolicy: 'network-only',
    });
    return result.data.getTransBuysByDate;
  } catch (err) {
    return [];
  }
};

const overviewTokensQuery = `
  query getOverviewTokens($limit: Int!, $skip: Int!){
    getOverviewTokens(limit: $limit, skip: $skip){
      total_supply
      update_time
      boileroom_balance
      treasury_balance
      fund_balance
      circulating_supply
      buy_fee
      sell_fee
      address
      burn_amount
      update_time
    }
  }	
`;

export const getOverviewTokensInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(overviewTokensQuery),
      variables: {
        limit: limit || 50,
        skip: skip || 0,
      },
      fetchPolicy: 'network-only',
    });

    return result.data.getOverviewTokens;
  } catch (err) {
    return [];
  }
};

const earnFarmsQuery = `
query getEarnFarms($limit: Int!, $skip: Int!){
  getEarnFarms(limit: $limit, skip: $skip){
    staking_token
    staking_token_name
    is_pair
    staking_token0
    staking_token1
    reward_token_name
    reward_token_address
    reciept_token
    allocation_point
    is_retired
    is_nft
    reward_token_decimals
    staking_token_balance
    lp_token0_balance
    lp_token1_balance
    master_pool
    reward_per_sec
    pool_start_time
    pool_end_time
    update_time  
    total_supply0
    total_supply1
    staking_supply
    stakingToken0_decimals
    stakingToken1_decimals
    total_alloc_point
    }
}	
  
`;

export const getEarnFarmsInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(earnFarmsQuery),
      variables: {
        limit: limit || 50,
        skip: skip || 0,
      },
      fetchPolicy: 'network-only',
    });

    return result.data.getEarnFarms;
  } catch (err) {
    return [];
  }
};

const earnStakesQuery = `
query getEarnStakers($limit: Int!, $skip: Int!){
  getEarnStakers(limit: $limit, skip: $skip){
    total_supply
    epoch
    next_epoch_time
    withdraw_lockups
    tvl
    rewards_emission_rate
    rewards_per_share
    apr
    reward_token_circulating
    period
    rewards_lockups
    address
    update_time
  }
}	
`;

export const getEarnStakesInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(earnStakesQuery),
      variables: {
        limit: limit || 50,
        skip: skip || 0,
      },
      fetchPolicy: 'network-only',
    });

    return result.data.getEarnStakers;
  } catch (err) {
    return [];
  }
};
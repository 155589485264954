import { getFarms, getMasterChefAbi, getMasterChefAddress } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import LpPairAbi from 'config/abi/LpPair.json';
import multicall from 'utils/multicall';
import { BigNumber } from 'ethers';
import { FarmCategory } from 'types/farm';

// single
export const fetchUserTokenDataSingle = async (account: string, chainId: string, farm: any, index: number) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);

  const masterChefAddress = getMasterChefAddress(selectedChainid, farm.farmType, farm.category, farm.masterChefAddress);
  if (!masterChefAddress) return { ...farm };

  const { name, stakingToken, receiptToken, rewardToken, rewardTokenDecimal } = farm;

  try{
    // fetch staking token balance and allowance
    const calls1 = [
      {
        address: stakingToken,
        name: 'allowance',
        params: [account, masterChefAddress],
      },
      {
        address: stakingToken,
        name: 'balanceOf',
        params: [account],
      },
    ];

    const [stakingTokenAllowanceRaw, balanceRaw] = await multicall(LpPairAbi, calls1);

    let userReceiptTokenAllowance = BigNumber.from(0);
    let isReciept = true;
    if( receiptToken.length > 0 ){
      if (farm.farmType === 'smeltRewardPool' && farm.category === FarmCategory.ACTIVE) {
        // fetch receipt token balance and allowance
        const calls2 = [
          {
            address: receiptToken,
            name: 'allowance',
            params: [account, masterChefAddress],
          },
        ];
    
        const [[receiptTokenAllowanceRaw]] = await multicall(LpPairAbi, calls2);
        userReceiptTokenAllowance = receiptTokenAllowanceRaw;
      }
    }
    else {
      isReciept = false;
    }

    return {
      name,
      userStakingTokenAllowance: stakingTokenAllowanceRaw[0],
      userStakingTokenBalance: balanceRaw[0],
      userReceiptTokenAllowance,
      rewardToken,
      rewardTokenDecimal,
      isReciept,
      isTokenDataUpdated: true,
      updateTokenDataIndex: index
    };

  }
  catch(err){
// 
  }

  return {
    name,
    userStakingTokenAllowance: BigNumber.from(0),
    userStakingTokenBalance: BigNumber.from(0),
    userReceiptTokenAllowance: BigNumber.from(0),
    rewardToken,
    rewardTokenDecimal,
    isReciept: false,
    isTokenDataUpdated: false,
    updateTokenDataIndex: index
  };
};

// multiple
export const fetchUserTokenData = async (account: string, chainId: string) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const farms = getFarms(selectedChainid);

  const tokenData = await Promise.all(
    farms.map(async (farm) => {
      const masterChefAddress = getMasterChefAddress(selectedChainid, farm.farmType, farm.category, farm.masterChefAddress);
      if (!masterChefAddress) return { ...farm };

      const { name, stakingToken, receiptToken, rewardToken, rewardTokenDecimal } = farm;

      try
      {
        // fetch staking token balance and allowance
        const calls1 = [
          {
            address: stakingToken,
            name: 'allowance',
            params: [account, masterChefAddress],
          },
          {
            address: stakingToken,
            name: 'balanceOf',
            params: [account],
          },
        ];

        const [allowanceRaw, balanceRaw] = await multicall(LpPairAbi, calls1);

        let userReceiptTokenAllowance = BigNumber.from(0);
        let isReciept = true;
        if( receiptToken.length > 0 ){
          if (farm.farmType === 'smeltRewardPool' && farm.category === FarmCategory.ACTIVE) {
            // fetch receipt token balance and allowance
            const calls2 = [
              {
                address: receiptToken,
                name: 'allowance',
                params: [account, masterChefAddress],
              },
            ];
    
            const [[receiptTokenAllowanceRaw]] = await multicall(LpPairAbi, calls2);
            userReceiptTokenAllowance = receiptTokenAllowanceRaw;
          }
        }
        else {
          isReciept = false;
        }

        if( farm.category === FarmCategory.TEAM )
        {
          userReceiptTokenAllowance = BigNumber.from(1000);
        }
        return {
          name,
          userStakingTokenAllowance: allowanceRaw[0],
          userStakingTokenBalance: balanceRaw[0],
          userReceiptTokenAllowance,
          rewardToken,
          rewardTokenDecimal,
          isReciept,
          isTokenDataUpdated: true,
          updateTokenDataIndex: 0
        };
      }
      catch(err){
        // 
      }
      return {
        name,
        userStakingTokenAllowance: BigNumber.from(0),
        userStakingTokenBalance: BigNumber.from(0),
        userReceiptTokenAllowance: BigNumber.from(0),
        rewardToken,
        rewardTokenDecimal,
        isReciept: false,
        isTokenDataUpdated: false,
        updateTokenDataIndex: 0
      };
    })
  );

  return tokenData;
};

// single
export const fetchUserFarmDataSingle = async (account: string, chainId: string, farm: any, index: number) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);

  const masterChefAddress = getMasterChefAddress(selectedChainid, farm.farmType, farm.category, farm.masterChefAddress);
  if (!masterChefAddress) return { ...farm };

  const { name, poolId, farmType, rewardToken, rewardTokenDecimal, category } = farm;

  try 
  {
  const calls = [
    {
      address: masterChefAddress,
      name: 'userInfo',
      params: [poolId, account],
    },
  ];

  const [userInfo] = await multicall(getMasterChefAbi(farmType, category, farm.masterChefAddress ), calls);

  // fetch staked amount and reward
  const calls2 = [
    {
      address: masterChefAddress,
      name: farmType === 'smeltRewardPool' ? 'pendingSMELT' : 'pendingReward',
      params: [poolId, account],
    },
  ];

  const [pendingRewardRaw] = await multicall(getMasterChefAbi(farmType, category, farm.masterChefAddress), calls2);
  return {
    name,
    stakedBalance: userInfo.amount,
    rewardTokenBalance: pendingRewardRaw[0],
    rewardToken,
    rewardTokenDecimal,
    isUpdated: true,
    updateIndex: index
  };
  }
  catch(err){
    console.log(err)
    return {
      name,
      stakedBalance: BigNumber.from(0),
      rewardTokenBalance: 0,
      rewardToken,
      rewardTokenDecimal,
      isUpdated: false,
      updateIndex: index
    };
  }

};

// multiple
export const fetchUserFarmData = async (account: string, chainId: string) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const farms = getFarms(selectedChainid);

  const farmData = await Promise.all(
    farms.map(async (farm) => {
      const masterChefAddress = getMasterChefAddress(selectedChainid, farm.farmType, farm.category, farm.masterChefAddress);
      if (!masterChefAddress) return { ...farm };

      const { name, poolId, farmType, rewardToken, rewardTokenDecimal, category } = farm;

      try{
      // fetch staked amount and reward
      const calls = [
        {
          address: masterChefAddress,
          name: 'userInfo',
          params: [poolId, account],
        }
      ];

      const [userInfo] = await multicall(getMasterChefAbi(farmType, category, farm.masterChefAddress), calls);

      // fetch staked amount and reward
      const calls2 = [
        {
          address: masterChefAddress,
          name: farmType === 'smeltRewardPool' ? 'pendingSMELT' : 'pendingReward',
          params: [poolId, account],
        },
      ];

      const [pendingRewardRaw] = await multicall(getMasterChefAbi(farmType, category, farm.masterChefAddress), calls2);
      return {
        name,
        stakedBalance: userInfo.amount,
        rewardTokenBalance: pendingRewardRaw[0],
        rewardToken,
        rewardTokenDecimal, 
        isUpdated: true,
        updateIndex: 0
      };
      }
      catch(err){
        console.log(err)
        return {
          name,
          stakedBalance: BigNumber.from(0),
          rewardTokenBalance: 0,
          rewardToken,
          rewardTokenDecimal,
          isUpdated: false,
          updateIndex: 0
        };
      }
    })
  );

  return farmData;
};

export const fetchFarmUserData = async (account: string, chainId: string) => {
  try{
    const tokenData = await fetchUserTokenData(account, chainId);
    const farmData = await fetchUserFarmData(account, chainId);
    const data = tokenData.map((row: any, index: number) => ({
      ...row,
      ...farmData[index],
    }));
    return data;
  }
  catch(err){
    console.log(err)
  }
  return [];
};
